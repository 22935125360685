import fullpagePopup from '@/lib/ui/fullpagePopup';
import { cr, ap } from '@/lib/ui/dom';
import { translate as t } from '@/util/i18n';
import map from '@/map/map';
import api from '@/util/api';
import errorMessage from '@/util/errorMessage';
import repeat from '@/lib/util/repeat';
import userChart from '@/views/newPositioning/userChart';
import userList from '@/views/newPositioning/userList';
import button from '@/lib/ui/button';
import i from '@/lib/ui/i';
import tabGroup from '@/lib/ui/tabGroup';
import hugBottom from '@/lib/util/hugBottom';
import { hide } from '@/lib/ui/showAndHide';

const statuses = [
  'error',
  'expired',
  'rejected',
  'noPos',
  'noTarget',
  'sent',
  'delivered',
  'confirmed',
];

const devicesToUsers = (devices) => {
  const users = {};
  devices.forEach((device) => {
    const userId = device.userId;
    let user = users[userId];
    if (!user) {
      user = {
        userId,
        userName: device.userName,
        profilePhoto: device.userProfilePhoto,
        positions: [],
        devices: [],
      };
      users[userId] = user;
    }

    user.devices.push(device);

    if (device.position.lat !== 0 && device.position.lng !== 0) {
      user.positions.push(device.position);
    }

    if (!user.status || statuses.indexOf(device.status) > statuses.indexOf(user.status)) {
      user.status = device.status;
    }
  });

  return Object.keys(users).map((userId) => users[userId]);
};

const positioningResults = (parent, alertId) => {
  const existingDeviceStats = {
    noPos: 0,
    rejected: 0,
    delivered: 0,
    confirmed: 0,
    error: 0,
    expired: 0,
    sent: 0,
    noTarget: 0,
  };

  const content = cr();

  const deliveryStats = userChart();

  const showInMap = (deviceId) => {
    tabs.openTab(1);
    positionsMap.showPositionById(deviceId);
  };

  const showInTable = (userId) => {
    tabs.openTab(0);
    userPositionList.mark(userId);
  };

  const userPositionList = userList(showInMap);

  const unableToFindSomeUsers = cr('p', 'unable-to-find-msg', t('searching'));

  const stats = ap(
    cr('div', 'stats'),
    deliveryStats.element,
    unableToFindSomeUsers,
    cr('h2', 'u-text-center', t('usersFound') + ':'),
    userPositionList.element,
  );
  hugBottom(stats, true);

  const positionsMap = map({
    onAutoFitEnabled: () => positionsMap.fitPositions(),
  });
  hugBottom(positionsMap.element, true);

  const tabs = tabGroup({
    pages: [
      {
        title: t('recipients'),
        content: stats,
        id: 'recipients',
      },
      {
        title: t('map'),
        content: positionsMap.element,
        id: 'map',
      },
    ],
    onTabChange: (page) => {
      if (page.id === 'map') {
        hugBottom(positionsMap.element);
        positionsMap.redrawTiles();

        if (positionsMap.isAutoFitEnabled()) {
          positionsMap.fitPositions();
        }
      }
    },
  });

  ap(content, tabs.element);

  let showNotFoundMsg = false;

  setTimeout(() => {
    api.getUserPositioningStats(alertId).then((userPositioningStats) => {
      showNotFoundMsg = true;
      if (userPositioningStats.sent > 0) {
        unableToFindSomeUsers.innerHTML = t('unableToFindSomeUsers', null, {
          users: userPositioningStats.sent,
        });
      }
    });
  }, 1000 * 30);

  const reloadPositions = () => {
    api.getUserPositioningStats(alertId).then((userPositioningStats) => {
      deliveryStats.update(userPositioningStats);

      if (showNotFoundMsg) {
        unableToFindSomeUsers.innerHTML = t('unableToFindSomeUsers', null, {
          users: userPositioningStats.sent,
        });
      }

      if (userPositioningStats.sent < 1) {
        hide(unableToFindSomeUsers);
      }
    });

    api.getPositioningDevices(alertId).then((res) => {
      const devices = res.devices;
      const users = devicesToUsers(devices);
      userPositionList.update(users);

      positionsMap.clearPositions();
      devices.forEach((device) => {
        positionsMap.addPosition({
          id: device.deviceId,
          name: device.userName + ' - ' + device.deviceName,
          latLng: device.position,
          popupContent: ap(
            cr('div'),
            cr('h3', 'u-typ-caption', device.userName),
            ap(cr('p'), t('device'), ': ', device.deviceName),
            ap(
              button(() => showInTable(device.userId), 'c-show-in-table-button'),
              i('arrow-left', 'inline'),
              t('showInTable'),
            ),
          ),
          onClick: () => showInTable(device.userId),
        });
      });
      if (positionsMap.isAutoFitEnabled()) {
        positionsMap.fitPositions();
      }
    });
  };

  const reloadData = () => {
    api
      .getDevicePositioningStats(alertId)
      .then((newDeviceStats) => {
        if (JSON.stringify(newDeviceStats) !== JSON.stringify(existingDeviceStats)) {
          Object.assign(existingDeviceStats, newDeviceStats); // Update stats
          reloadPositions();
        }
      })
      .catch((res) =>
        errorMessage(t('getPositioningStatsError'), t('getPositioningStatsErrorMsg'), res),
      );
  };

  fullpagePopup(parent, content, 'c-positioning-results');

  setTimeout(() => {
    repeat.patternIfBodyContains(
      reloadData,
      parent,
      [6000, 2000, 2000, 2000, 2000, 2000, 2000, 2000, 5000, 5000, 5000, 5000, 10000],
    );
  }, 200);

  reloadData();
};

export default positioningResults;

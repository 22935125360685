import { ap, cr, ac, dc, st, qa } from './dom';
import is from './../util/is';
import button from './button';

/**
 * @callback config.onTabChange
 * @param {object} page the page that opened
 * @param {number} index the tab index
 */

/**
 * @callback config.tabButtonRenderer
 * @param {HTMLButtonElement} tabButton the default tab button
 * @param {number} index the tab index
 */

/**
 * @callback config.shouldChangeTab
 * @param {number} newTabIndex the index after the tab change
 * @param {number} currentIndex the index before the tab change
 */

/**
 * @typedef TabPage
 *
 * @prop {string} title the page title
 * @prop {HTMLElement} content the page content
 */

/**
 * Creates a tab group
 *
 * @param {object} config the confuration object of the tab group
 * @param {TabPage[]}  config.pages an array of TabPage
 * @param {number} [config.startPage] the page index to start with (optional).
 *                                   Defaults to 0 (first).
 * @param {requestCallback} [config.onTabChange] a callback for when a tab has
 *                                               changed.
 * @param {requestCallback} [config.tabButtonRenderer] a callback to render
 *                                                     buttons.
 * @param {requestCallback} [config.shouldChangeTab] a callback to decide if
 *                                                   page should change or not.
 * @param {string} [config.className] additional className for the root element.
 * @param {string} [config.navClass] additional className for the navigation.
 * @param {string} [config.pageClass] additional className for the page holder.
 */
const tabGroup = (config) => {
  let currentTab = is.number(config.startPage) ? config.startPage : 0;
  const navClass = config.navClass ? ' ' + config.navClass : '';
  const pageClass = config.pageClass ? ' ' + config.pageClass : '';
  const tabNav = cr('nav', 'c-tab-list' + navClass);
  const pageHolder = cr('div', 'c-tab-page-holder' + pageClass);

  const buttons = [];
  const pages = [];

  config.pages.forEach((page, index) => {
    const tabButton = button(() => openTab(index), 'c-tab tab-' + index);

    buttons.push(tabButton);
    ap(
      tabNav,
      is.function(config.tabButtonRenderer)
        ? config.tabButtonRenderer(tabButton, page, index)
        : ap(tabButton, page.title),
    );

    const tabPage = cr('div', 'c-tab-page page-' + index);
    tabPage.tabIndex = -1;
    ap(tabPage, page.content);
    pages.push(tabPage);
    ap(pageHolder, tabPage);
  });

  const wrapper = cr('div', 'page-holder-wrapper');

  const openTab = (index, callOnTabChange) => {
    if (config.shouldChangeTab && !config.shouldChangeTab(index, currentTab)) {
      return;
    }

    currentTab = index;
    const button = buttons[index];
    const page = pages[index];

    buttons.forEach((button) => dc(button, 'active'));
    ac(button, 'active');
    pages.forEach((page) => dc(page, 'active'));
    ac(page, 'active');

    const content = config.pages[index].content;
    const elementsToIgnore = qa('input, button, textarea, select, a, fieldset', wrapper);
    elementsToIgnore.forEach((fElement) => {
      fElement.tabIndex = -1;
    });
    const focusableElements = qa('input, button, textarea, select, a', content);
    focusableElements.forEach((fElement) => {
      fElement.tabIndex = 0;
    });

    // wrapper.scrollTo({
    //   behavior: 'smooth',
    //   top: 0,
    //   left: wrapper.getBoundingClientRect().width * index
    // });

    wrapper.scrollTop = 0;
    wrapper.scrollLeft = 0;
    st(pageHolder, {
      marginLeft: '-' + index * 100 + '%',
    });

    if (is.function(config.onTabChange) && callOnTabChange !== false) {
      config.onTabChange(config.pages[index], index);
    }
  };

  const element = ap(
    cr('div', 'c-tab-group' + (is.string(config.className) ? ' ' + config.className : '')),
    tabNav,
    ap(wrapper, pageHolder),
  );

  element.tabIndex = -1;

  openTab(currentTab, false);

  return {
    element,
    openTab,
    pageHolder,
    getCurrentTab: () => currentTab,
  };
};

export default tabGroup;

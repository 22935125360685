const repeat = {
  /**
   * Repeats a function as long as a boolean function returns true.
   *
   * @param {Function} fn the function to repeat
   * @param {Function} conditionFn the boolean function to check
   * @param {number} [interval] the interval in milliseconds. Defaults to 5000
   */
  ifCondition: (fn, conditionFn, interval) => {
    const id = setInterval(() => {
      if (conditionFn()) {
        fn();
      } else {
        clearInterval(id);
      }
    }, interval || 5000);

    return {
      stop: () => clearInterval(id),
    };
  },

  /**
   * Repeats a function as long as an element is contained in the HTML body.
   *
   * @param {Function} fn the function to repeat
   * @param {Node} element the element to check if body contains
   * @param {number} [interval] the interval in milliseconds. Defaults to 5000
   */
  ifBodyContains: (fn, element, interval) =>
    repeat.ifCondition(fn, () => document.body.contains(element), interval),

  /**
   * Repeats a function in a pattern as long as a boolean function returns true.
   *
   * @param {Function} fn the function to repeat
   * @param {Function} conditionFn the boolean function to check
   * @param {number[]} pattern the pattern to follow, in milliseconds
   */
  patterinIfCondition: (fn, conditionFn, pattern) => {
    let index = 0;
    const nextRepetition = () => {
      const currentTimeout = pattern[index];
      if (conditionFn()) {
        fn();

        if (index + 1 < pattern.length) {
          index++;
        }

        setTimeout(nextRepetition, currentTimeout);
      }
    };
    nextRepetition();
  },

  /**
   * Repeats a function in a pattern as long as an element is contained in the
   * HTML body.
   *
   * @param {Function} fn the function to repeat
   * @param {Function} element the element to check if body contains
   * @param {number[]} pattern the pattern to follow, in milliseconds
   */
  patternIfBodyContains: (fn, element, pattern) =>
    repeat.patterinIfCondition(fn, () => document.body.contains(element), pattern),
};

export default repeat;

import { cr, ap } from '@/lib/ui/dom';
import { translate as t } from '@/util/i18n';
import i from '@/lib/ui/i';
import button from '@/lib/ui/button';
import appear from '@/lib/ui/appear';
import { profilePhotoSrc } from '@/lib/ui/profilePhoto';

const userList = (showInMap) => {
  const tbody = cr('tbody');
  const element = ap(
    cr('table', 'c-table'),
    ap(
      cr('thead'),
      ap(
        cr('tr'),
        cr('td', null, t('photo')),
        cr('td', null, t('name')),
        cr('td', null, t('devices')),
        cr('td', null, t('positionInMap')),
      ),
    ),
    tbody,
  );

  let userRowMap = {};

  const update = (users) => {
    userRowMap = {};

    tbody.innerHTML = '';

    ap(
      tbody,
      users.map((user) => {
        const showUserInMap = () => {
          showInMap(user.devices[0].deviceId);
        };

        const userRow = ap(
          cr('tr'),
          cr('td', null, profilePhotoSrc(user.userName, user.profilePhoto)),
          cr('td', null, user.userName),
          ap(cr('td'), i('mobile'), ' ', user.devices.length),
          ap(cr('td', 'c-table-row-actions'), ap(button(showUserInMap), i('map'))),
        );

        userRowMap[user.userId] = userRow;

        return userRow;
      }),
    );
  };

  const mark = (userId) => {
    const userRow = userRowMap[userId];
    appear(userRow);
    userRow.scrollIntoView();
  };

  return {
    element,
    update,
    mark,
  };
};

export default userList;

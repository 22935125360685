import { ap, cr, rm, ac, dc, on } from './dom';
import backButton from './backButton';

const fullpagePopup = (parent, content, className, onBackPressed) => {
  const root = cr('div', 'c-fullpage-popup' + (className ? ' ' + className : ''));

  const close = () => {
    dc(root, 'slide-in');
    if (onBackPressed) {
      onBackPressed();
    }
    setTimeout(() => rm(root), 300);
  };

  ap(parent, ap(root, backButton(close), content));

  on(window, 'keydown', (event) => {
    if (event.key === 'Escape') {
      close();
    }
  });

  setTimeout(() => ac(root, 'slide-in'), 100);
};

export default fullpagePopup;

import button from './button';
import i from './i';
import is from '../util/is';

const back = () => {
  history.back();
};

/**
 *
 * @param {Function} [backAction] function to call when clicked. If not set, the
 *                                default behavior is history.back();
 */
const backButton = (backAction) =>
  button(
    is.function(backAction) ? backAction : back,
    'circle c-back-button',
    i('arrow-left fa-fw', 'inline'),
  );

export default backButton;

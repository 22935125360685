import { cr, ap } from '@/lib/ui/dom';
import { translate as t } from '@/util/i18n';
import { Chart, ArcElement, DoughnutController, Legend, Tooltip } from 'chart.js';
import { redDark, yellowPrimary, bluePrimary } from '@/lib/ui/colors';

Chart.register(ArcElement, DoughnutController, Legend, Tooltip);

const userChart = () => {
  const element = cr('div', 'user-chart');

  const chartElement = cr('canvas', null, null, {
    width: 200,
    height: 200,
  });

  const successPercentage = cr('label', 'success-percentage');

  ap(element, chartElement, successPercentage);

  const chart = new Chart(chartElement, {
    type: 'doughnut',
    data: {
      datasets: [
        {
          data: [0, 0, 0, 0],
          backgroundColor: [bluePrimary, yellowPrimary, redDark],
          borderColor: 'transparent',
        },
      ],
      labels: [t('delivered'), t('pending')],
    },
    options: {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
      },
      cutout: '75%',
    },
  });

  const update = (newStats) => {
    const dataset = chart.data.datasets[0];
    dataset.data = [newStats.delivered, newStats.sent, newStats.noPos, newStats.noTarget];

    const labels = [t('delivered'), t('pending')];

    if (newStats.noPos > 0) {
      labels.push(t('noPos'));
    }

    if (newStats.noTarget > 0) {
      labels.push(t('noTarget'));
    }

    dataset.labels = labels;

    let success =
      newStats.delivered /
      (newStats.delivered + newStats.sent + newStats.noPos + newStats.noTarget);

    if (isNaN(success)) {
      success = 0;
    }

    successPercentage.innerHTML = Math.round(success * 100) + '%';

    chart.update();
  };

  return {
    update,
    element,
  };
};

export default userChart;
